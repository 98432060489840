import { useContext, useEffect } from 'react';
import PopupNotificationContainer from '@components/Notifications';
import Navbar, { NavbarBanner } from '@components/Navbar';
import Footer from '@components/Footer';
import { ThemeContext } from '@store/contexts';
import {Outlet} from 'react-router-dom';
import { Error503Page } from './Issues';

const showNavbarBanner = true;
export default function Layout() {
    const theme = useContext(ThemeContext);

    useEffect(() => {
        if (theme.dark) {
            document.body.style.backgroundColor = '#000';
        } else {
            document.body.style.backgroundColor = '';
        }
    }, [theme]);

    return (
        <div className={`themeContainer ${theme.dark ? 'dark' : ''}`}>
            {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ?
                <Error503Page />
            :
            <>
                <PopupNotificationContainer />
                { showNavbarBanner ? <NavbarBanner /> : <></> }
                <Navbar />
                <div className={`App ${theme.dark ? 'dark' : ''}`}>
                    <Outlet />
                </div>
                <Footer />
            </>}
        </div>
    )
}